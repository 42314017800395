import styled from '@emotion/styled';



export const Container = styled.section`
  width: 90%;
  max-width: var(--maxWidth);
  margin: 0 auto;

`;
